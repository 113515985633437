export default function CirclePlusIcon() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10" r="10" fill="#0A9373" />
      <path
        d="M11.1923 9.30769H15V10.6923H11.1923V14.5H9.80769V10.6923H6V9.30769H9.80769V5.5H11.1923V9.30769Z"
        fill="#F9F4ED"
      />
    </svg>
  );
}
